// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo"
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = true
import "@hotwired/stimulus"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
require("@fortawesome/fontawesome-free");
import "@fortawesome/fontawesome-free/css/all.css";
import 'bootstrap-icons/font/bootstrap-icons.css'


require("trix")
require("@rails/actiontext")

Rails.start()
ActiveStorage.start()
