import { Controller } from "@hotwired/stimulus"

// TO TEST => drop this in any HTML

// <div data-controller="hello">
//   <input data-hello-target="name" type="text">
//   <button data-action="click->hello#greet">
//     Greet
//   </button>
//   <span data-hello-target="output">
//   </span>
// </div>

export default class extends Controller {
  static targets = [ "name", "output" ]

  greet() {
    this.outputTarget.textContent =
      `Hello, ${this.nameTarget.value}!`
  }
  connect() {
    console.log("hello from hello StimulusJS")
  }


}